<template>
  <div>
    <Breadcrumbs :main="$tc('models.article.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';
import ContentFormsMixin from 'mixins/ContentFormsMixin';

export default {
  name: 'ArticleForm',
  data() {
    return {
      modelName: 'Article',
      fields: {
        title: {
          type: 'text',
          key: 'title',
          value: '',
          label: this.$t('models.article.attributes.title'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        },
        image: {
          type: 'file',
          key: 'image',
          value: '',
          label: this.$t('models.article.attributes.image'),
          acceptedFiles: 'image/*',
          required: false,
          valid: true,
          classes: 'col-12 py-0',
          fileType: 'image',
          origFile: null
        },
        body: {
          type: 'rich-text',
          key: 'body',
          value: '',
          label: this.$t('models.article.attributes.body'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        },
        searchableItemAttributes: {
          type: 'searchableSelect',
          allowMultiple: true,
          taggable: true,
          key: 'searchableItemAttributes',
          value: [],
          searchData: [],
          autocompleteAction: this.suggestTags,
          label: this.$t('models.article.attributes.tags'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      record: 'article',
      tagsList: 'tagsList'
    })
  },
  methods: {
    ...mapActions({
      getRecord: 'getArticle',
      createRecord: 'createArticle',
      updateRecord: 'updateArticle',
      searchTags: 'searchTags'
    })
  },
  mixins: [FormsMixin, ContentFormsMixin]
};
</script>
